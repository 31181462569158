// Vendor
require('popper.js/dist/umd/popper');
require('bootstrap/dist/js/bootstrap');

import "@fortawesome/fontawesome-free/js/all";

// Local
require('blacklight-frontend/app/javascript/blacklight/core');
require('blacklight-frontend/app/javascript/blacklight/autocomplete');
// require('blacklight-frontend/app/javascript/blacklight/checkbox_submit');
require('blacklight-frontend/app/javascript/blacklight/modal');
// require('blacklight-frontend/app/javascript/blacklight/bookmark_toggle');
require('blacklight-frontend/app/javascript/blacklight/collapsable');
require('blacklight-frontend/app/javascript/blacklight/facet_load');
require('blacklight-frontend/app/javascript/blacklight/search_context');
// doing the above rather than require('blacklight-frontend/app/assets/javascripts/blacklight/blacklight')
// each script may have an import it's doing

import wireUpPopovers from "../shared/popovers";

Blacklight.onLoad(function() {

    let $isBentoActive;

    //Enable bootstrap popovers - currently used for info on Bento in header
    $('body').popover({
        trigger: "focus",
        sanitize: false,
        html: true,
        animation: true,
        selector: '.beta-popover',
        container: 'body',
    });

    wireUpPopovers();

    $('body').on('click', '#toggle-bento', function() {
        /* done this way because of issue with prepend function used to combine menus in mobile view. */
        $isBentoActive = $('#toggle-bento').hasClass('active');
        $(this).button('toggle').toggleClass('focus');
        let userQuery = document.querySelector('#discovery-search-input').value;
        let newLocation = '';
        if($isBentoActive) {
            newLocation = `${$('#toggle-bento').data('classic-home')}?bento_off=true`;
            if(userQuery) newLocation += `&search_query=${userQuery}`;
        } else {
            newLocation = `${$('#toggle-bento').data('bento-home')}?bento_on=true`;
            if(userQuery) newLocation += `&bento_search_query=${userQuery}`;
            }
        window.location = newLocation;
    });

    const classicTextBoxSelector = '#sbClassic input[name=search_galileo_query]';
    const bentoTextBoxSelector = '#sbBento input[name=bento_search_query]';
    $('#sbClassicTab').click(function() {
        // classic on
        let cookie_eol = (new Date(Date.now() + 2 * 365 * 24 * 3600 * 1000)).toUTCString();
        document.cookie = `classic=true;  expires=${cookie_eol}; path=/`;
        $(classicTextBoxSelector).val($(bentoTextBoxSelector).val());
    });

    $('#sbBentoTab').click(function() {
        // classic off
        document.cookie = "classic=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        $(bentoTextBoxSelector).val($(classicTextBoxSelector).val());
    });

    //close mobile navbar and scroll to spotlight section on click of spotlight link
    $('#scroll_to_spotlight').click(function(e) {
        e.preventDefault();
        $('#navbarBottom').collapse('hide');
        $('.navbar-toggler').addClass('collapsed');
        window.location = "#spotlight";
    });

    // add no focus class to hamburger menu to prevent border ring
    $('.navbar-toggler').addClass('u-no-focus-outline');
    // also added to change view dropdown
    $('#navbarDropdown').addClass('u-no-focus-outline');

    $(document).on('keyup', function(e) {
        // for accessibility, remove class if user is using keyboard(tab) to navigate site
        if (e.keyCode === 9) { //tab key
            e.preventDefault();
            $('.navbar-toggler').removeClass('u-no-focus-outline');
            $('#navbarDropdown').removeClass('u-no-focus-outline');
        }
        // close navbar if user presses esc key - accessibility
        if (e.keyCode === 27) { //escape key
            e.preventDefault();
            $('#navbarBottom').collapse('hide');
            $('.navbar-toggler').addClass('collapsed');
        }
    });

    // keyboard nav for change view dropdown
    $('.dropdown-menu a').keydown(function(e){
        //home key goes to first item in cards. end key goes to last item in cards.
        //only on mobile view
        switch(e.which) {
            case 36: // home
                e.preventDefault();
                $(this).closest('.dropdown-menu').find('a:first').focus();
                break;
            case 35: // end
                e.preventDefault();
                $(this).closest('.dropdown-menu').find('a:last').focus();
                break;
            case 39: // right
                e.preventDefault();
                $(this).closest('.col').next('.col').find('a').focus();
                break;
            case 37: // left
                e.preventDefault();
                $(this).closest('.col').prev('.col').find('a').focus();
                break;
        }
    });

    // keyboard nav for change view modal
    $('#view-modal a').keydown(function(e){
        //home key goes to first item in cards. end key goes to last item in cards.
        //only on mobile view
        switch(e.which){
            case 36: // home
                e.preventDefault();
                $(this).closest('.modal-body').find('a:first').focus();
                break;
            case 35: // end
                e.preventDefault();
                $(this).closest('.modal-body').find('a:last').focus();
                break;
            case 39: // right
                e.preventDefault();
                $(this).closest('.col').next('.col').find('a').focus();
                break;
            case 37: // left
                e.preventDefault();
                $(this).closest('.col').prev('.col').find('a').focus();
                break;
        }
    });

    //combine navigations in header on mobile
    $('#navbarBottom').on('show.bs.collapse', function () {
        $('#navbarBottom ul').prepend($('#navbarTop .navbar-nav').html());
        $('body').addClass('overflow-hidden');
        $('body').append('<div class="modal-backdrop fade show" data-toggle="collapse" data-target=".navbar-toggle"></div>');
        $('#navbarBottom a').keydown(function(e){
            //home key goes to first item in nav. end key goes to last item in nav.
            //only on mobile view
            switch(e.which){
                case 36: // home
                    e.preventDefault();
                    $(this).closest('#navbarBottom').find('a:first').focus();
                    break;
                case 35: // end
                    e.preventDefault();
                    $(this).closest('#navbarBottom').find('a:last').focus();
                    break;
                case 40: // down
                    e.preventDefault();
                    $(this).closest('li').next('li').find('a').focus();
                    break;
                case 38: // up
                    e.preventDefault();
                    $(this).closest('li').prev('li').find('a').focus();
                    break;
            }
        });
    });
    $('#navbarBottom').on('hidden.bs.collapse', function () {
        $('#navbarBottom .nav-top').remove();
        $('body').removeClass('overflow-hidden');
        $('.modal-backdrop').remove();
    });
    $(window).on('resize', function () {
        if (window.innerWidth > 768) {
            $('#navbarBottom').collapse('hide');
            $('.navbar-toggler').addClass('collapsed');}
    });

    function compareSteps(elementA, elementB) {
        return parseInt($(elementA).data('step')) - parseInt($(elementB).data('step'))
    }

    function collapseAndThen(selector, then) {
        $(selector).one('hidden.bs.collapse', then);
        $(selector).collapse('hide');
    }

    function showTour() {
        computeTourSteps();
        introJs().setOptions({
            'showBullets': false,
            'scrollTo': 'tooltip',
            'hideNext': true,
            'skipLabel': 'Exit',
            'steps': $('[data-step]:visible').sort(compareSteps).map(function (i, el) {
                return {
                    element: el,
                    intro: $(el).data('intro'),
                    highlightClass: $(el).data('highlightclass')
                }
            })
        }).oncomplete(scrollToTop).start();
    }

    function scrollToTop() {
        const tourButton = $('.in-page-help')[0];
        if (tourButton) {
            if (tourButton.scrollIntoViewIfNeeded) {
                tourButton.scrollIntoViewIfNeeded();
            } else {
                tourButton.scrollIntoView();
            }
        }
    }

    // do the tour!
    $('body').on('click', '.in-page-help', function(e) {
        e.preventDefault();
        if ($(e.target).closest('.in-page-help').is('[data-limited-tour]')) {
            $('[data-intro]:not(main [data-intro])').removeAttr('data-intro');
        }
        if ($('.navbar-toggler').hasClass('collapsed')) {
            showTour();
        } else {
            collapseAndThen('#navbarBottom', showTour);
            $('.navbar-toggler').addClass('collapsed');
        }
    });

    /*
       support for file_field updates
       */
    $('.custom-file-input').change(function(e){
        var fileName = e.target.files[0].name;
        $('.custom-file-label[for=' + e.currentTarget['id'] + ']').html(fileName);
    });

    /*
    Popup view selector
     */
    let $view_link = $('#view-modal-link');
    if($view_link.data('popup')) {
        $view_link.click();
    }

    let $show_views = $('#navbarDropdown');
    if($show_views.data('popup')) {
        $('#view-modal').modal({show: true, backdrop: 'static', keyboard: false})
    }

    /*
    Copy Express Link
     */
    $('body').on('click', '.copy-sibling-value', function() {
        var input = this.parentElement.previousElementSibling;
        input.select();
        document.execCommand('copy');
    });

    /*
    unique uuid for embedded search box div id
    */
    function uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    }

    /*
    change the div id when the modal opens
    */
    $('body').on('show.bs.modal', function (event) {
        let child = $(event.target).find('.div-id-uuid');
        if (child.length) $(child).html($(child).html().replace(/[a-f\d]{4}(?:[a-f\d]{4}-){4}[a-f\d]{12}/ig, uuidv4()));
    });

    /*
    refresh: close and reopen the modal to get a new div id
    */
    $('body').on('click', '.reopen-modal-button', function() {
        let modal = $(this).closest('.modal');
        $(modal).modal('hide');
        $(modal).on('hidden.bs.modal', function() {
            setTimeout(function() { $(modal).modal('show'); }, 300);
            $(modal).off('hidden.bs.modal');
        });
    });

    /*
    Fix close button for nested modals
     */
    $('body').on('click', '[data-dismiss-closest-modal]', function () {
        $(this).closest('.modal').modal('hide');
    });

});

// Sort tour elements first by their data-step-rank attribute (if provided; otherwise defaults to 1), then by their order within the page.
// So, you can just set data-intro for most everything, and then set data-step-rank to higher numbers for the few elements
// you want to make sure are last in the tour.
function computeTourSteps() {
    // clear any previous step numbers
    $('[data-step]').attr('data-step', null);
    // preprocess walkthru steps
    const introFirstOnlyItems = {};
    $('[data-intro-first-only]').each(function (i, el) {
        const text = $(el).data('intro-first-only');
        if (introFirstOnlyItems[text]) return;
        introFirstOnlyItems[text] = true;
        $(el).attr('data-intro', text).attr('intro-first-only', null);
    });
    let walkthruElements = $('[data-intro]');
    let walkthru = walkthruElements.map(function (i, el){
        let orderWithinPage = i/walkthruElements.length;
        let rank = parseInt($(el).data('step-rank') || 1) * 10 + orderWithinPage;
        return {element: el, rank};
    });
    walkthru.sort(function (a, b){
        return a.rank - b.rank;
    });
    walkthru.map(function (i, item){
        $(item.element).attr('data-step', i + 1);
    });
}
