export default function wireUpPopovers() {
    $('.beta-popover:not([data-popover-wired])').each(function (i, el) {
        let betaPopoverShownViaFocus = false;
        let clickState = null;
        $(el).attr('data-popover-wired', true).mouseenter(function () {
            $(this).popover('show');
            $('.beta-popover').each(function (j, otherEl) {
                if (el !== otherEl) $(otherEl).popover('hide');
            });
        }).mouseleave(function () {
            if (!betaPopoverShownViaFocus) {
                $(this).popover('hide');
            }
        }).on('mousedown', function (){
            clickState = 'non-focus-causing';
        }).on('focus', function () {
            if (clickState) clickState = 'focus-causing';
            betaPopoverShownViaFocus = true;
        }).on('blur', function () {
            betaPopoverShownViaFocus = false;
        }).on('mouseup', function (e) {
            if (clickState === 'non-focus-causing') {
                $(el).blur();
            }
        });
        $(document).on('mouseup', function () { clickState = null; });
    });
}